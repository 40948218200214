<template>
  <div class="mortgage-result-broker">
    <div class="mortgage-parameters">
      <img
        style="object-fit: contain"
        :src="application.bank.logo_square"
        loading="lazy"
        alt=""
        class="bank-logo"
      />
      <!-- suma vybraného úveru -->
      <div
        class="bold wrap100px"
        :class="{'red-text-custom' : application.backEndLimitCorrected}"
      >
        {{ getTotalAmountBasedOnCorrection() | dotTheAmount }} EUR
      </div>
      <!-- fixacia vybraneho uveru -->
      <div
        class="bold wrap50px"
      >
        {{ application.fix }}
      </div>
      <!-- pozadovana sadzby vybraneho financovania -->
      <div
        class="bold wrap50px"
      >
        {{ application.sadzba_pozadovana | toFloor(2) }}<span class="upper-index">%</span>
      </div>
      <!-- splátka vybraného uveru -->
      <div
        class="bold wrap100px"
      > {{ application.splatka | dotTheAmount }} <span class="upper-index">EUR</span>
      </div>
      <!-- splatnost pozadovaneho uveru -->
      <div
        class="bold wrap50px"
      >
        {{ application.splatnost }} <span class="upper-index">R</span>
      </div>
      <!-- poplatok za poskytnutie vybraneho financovania -->
      <div
        class="bold wrap50px"
      > {{  application.poplatok_za_poskytnutie | dotTheAmount }} <span class="upper-index">EUR</span>
      </div>
      <div class="d-flex MGtop20px">
        <div class="text-cta" @click="deleteApplication()">
          ZRUŠIŤ
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { deleteMortgageApplicationByID } from "@/services/brokerService";
import imagesConvert from "../../../../../mixins/imagesConvert";

export default {
  name: "MortgageApplicationItemPicked",
  props: {
    application: Object,
    prePicked: Boolean,
  },
  mixins: [
    imagesConvert,
  ],
  created () {
    this.picked = this.prePicked;
  },
  methods: {
    getTotalAmountBasedOnCorrection() {
      if (this.application.stage === 'rozpracovana') {
        if (this.application.backEndLimitCorrected) {
          return this.application.correctedAmount;
        }
      }
      return (
          this.application.suma_nadobudnutie +
          this.application.suma_refinancovanie +
          this.application.suma_bezucel
      );
    },
    async deleteApplication () {
      this.loading = true;
      await deleteMortgageApplicationByID(this.application.id);
      this.$store.commit("mortgageDetailState/deleteApplication", this.application.id);
      this.loading = false;
    },
  },
  data: () => ({
    picked: false,
    loading: false,
  }),
}
</script>

<style scoped>

</style>
