<template>
  <div  id="chat-wrapper">
    <div class="chat-block-broker">
      
      <div class="chat-broker-header">
        <div
          class="header-chat-broker"
        >
          <div class="back-button-wrapper pointer" @click="closePopup">
            <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg" alt=""/>
          </div>
          <client-chat-share-handler :client-object="getClientShareInfo()"/>
        </div>
        <div class="client-name-block">
          <img
            src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f37_avatar%20cropped.png"
            loading="lazy"
            alt=""
            class="image-10"
          />
          <div class="div-block-28">
            <user-manager-handler :rules="['single']" :client-info="client" client clients/>
          </div>
        </div>
        <div class="stages hideSmartphone">
          <div class="stage-line white">
            <div class="stage-line-hypoteka" :style="'width: ' + checkPoints * 25 + '%;'"></div>
            <div class="stages-container _4steps">
              <div class="stage" v-for="(barItem, index) in progressBarData" :key="index">
                <div class="stage-icon" :class="[checkPoints > index ? 'true-hypoteka' : '']"></div>
                <div class="stage-text" :class="[checkPoints > index ? 'true' : '']">
                  <div class="stage-title">{{ barItem.label }}</div>
                  <div class="extra-small-text">{{ barItem.date }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div
        class="chat-panel-broker broker flex-column-reverse"
        v-if="clientChatInitialized"
      >
        <div
          v-for="(message, key) in messages"
          :class="[
            message.sender === client.id
              ? 'question-broker-chat'
              : 'question-block-broker',
          ]"
          :key="key"
        >
          <div
            :class="[
              message.sender === client.id
                ? 'message-answer'
                : 'message-question',
            ]"
          >
            <div
              :class="[
                message.sender === client.id
                  ? 'text-message-left'
                  : 'text-message-right-2',
              ]"
              v-if="isUrl(message.content)"
            >
              {{ message.content }}
            </div>
            <div class="text-message-right-2" v-html="urlify(message.content)" v-else></div>
            <div class="file-to-download" v-if="message.attachment">
              <a
                :class="[
                  message.sender === client.id ? 'white--text' : 'bl-color',
                ]"
                style="text-decoration: none"
                class="download-link-2"
                target="_blank"
                :href="message.attachment"
                :download="message.attachment_filename"
              >{{ message.attachment_filename }}</a
              >
              <svg
                v-if="message.sender !== client.id"
                width="25"
                height="25"
                viewBox="0 0 15 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.2808 11.667H3.92889C3.75039 11.667 3.60514 11.5363 3.60514 11.3753V2.62533C3.60514 2.46433 3.75039 2.33366 3.92889 2.33366H6.52181V4.75449C6.52181 5.67149 7.23173 6.41699 8.10498 6.41699H10.6051V11.3753C10.6051 11.5363 10.4593 11.667 10.2808 11.667ZM10.4004 5.25033H8.10498C7.87514 5.25033 7.68848 5.02808 7.68848 4.75449V2.33366H7.75381L10.4004 5.25033ZM11.6201 4.85833L8.44448 1.35833C8.33423 1.23641 8.17731 1.16699 8.01223 1.16699H3.92889C3.10698 1.16699 2.43848 1.82149 2.43848 2.62533V11.3753C2.43848 12.1792 3.10698 12.8337 3.92889 12.8337H10.2808C11.1027 12.8337 11.7718 12.1792 11.7718 11.3753V5.25033C11.7718 5.10508 11.7176 4.96566 11.6201 4.85833Z"
                  fill="$primary"
                />
                <mask
                  id="mask0_1400_480"
                  style="mask-type: alpha"
                  maskUnits="userSpaceOnUse"
                  x="2"
                  y="1"
                  width="10"
                  height="12"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.2808 11.667H3.92889C3.75039 11.667 3.60514 11.5363 3.60514 11.3753V2.62533C3.60514 2.46433 3.75039 2.33366 3.92889 2.33366H6.52181V4.75449C6.52181 5.67149 7.23173 6.41699 8.10498 6.41699H10.6051V11.3753C10.6051 11.5363 10.4593 11.667 10.2808 11.667ZM10.4004 5.25033H8.10498C7.87514 5.25033 7.68848 5.02808 7.68848 4.75449V2.33366H7.75381L10.4004 5.25033ZM11.6201 4.85833L8.44448 1.35833C8.33423 1.23641 8.17731 1.16699 8.01223 1.16699H3.92889C3.10698 1.16699 2.43848 1.82149 2.43848 2.62533V11.3753C2.43848 12.1792 3.10698 12.8337 3.92889 12.8337H10.2808C11.1027 12.8337 11.7718 12.1792 11.7718 11.3753V5.25033C11.7718 5.10508 11.7176 4.96566 11.6201 4.85833Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_1400_480)">
                  <rect x="0.104492" width="14" height="14" fill="#0019D9"/>
                </g>
              </svg>
              <svg
                v-else
                width="25"
                height="25"
                viewBox="0 0 15 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.2808 11.667H3.92889C3.75039 11.667 3.60514 11.5363 3.60514 11.3753V2.62533C3.60514 2.46433 3.75039 2.33366 3.92889 2.33366H6.52181V4.75449C6.52181 5.67149 7.23173 6.41699 8.10498 6.41699H10.6051V11.3753C10.6051 11.5363 10.4593 11.667 10.2808 11.667ZM10.4004 5.25033H8.10498C7.87514 5.25033 7.68848 5.02808 7.68848 4.75449V2.33366H7.75381L10.4004 5.25033ZM11.6201 4.85833L8.44448 1.35833C8.33423 1.23641 8.17731 1.16699 8.01223 1.16699H3.92889C3.10698 1.16699 2.43848 1.82149 2.43848 2.62533V11.3753C2.43848 12.1792 3.10698 12.8337 3.92889 12.8337H10.2808C11.1027 12.8337 11.7718 12.1792 11.7718 11.3753V5.25033C11.7718 5.10508 11.7176 4.96566 11.6201 4.85833Z"
                  fill="#0019D9"
                />
                <mask
                  id="mask0_1400_480"
                  style="mask-type: alpha"
                  maskUnits="userSpaceOnUse"
                  x="2"
                  y="1"
                  width="10"
                  height="12"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.2808 11.667H3.92889C3.75039 11.667 3.60514 11.5363 3.60514 11.3753V2.62533C3.60514 2.46433 3.75039 2.33366 3.92889 2.33366H6.52181V4.75449C6.52181 5.67149 7.23173 6.41699 8.10498 6.41699H10.6051V11.3753C10.6051 11.5363 10.4593 11.667 10.2808 11.667ZM10.4004 5.25033H8.10498C7.87514 5.25033 7.68848 5.02808 7.68848 4.75449V2.33366H7.75381L10.4004 5.25033ZM11.6201 4.85833L8.44448 1.35833C8.33423 1.23641 8.17731 1.16699 8.01223 1.16699H3.92889C3.10698 1.16699 2.43848 1.82149 2.43848 2.62533V11.3753C2.43848 12.1792 3.10698 12.8337 3.92889 12.8337H10.2808C11.1027 12.8337 11.7718 12.1792 11.7718 11.3753V5.25033C11.7718 5.10508 11.7176 4.96566 11.6201 4.85833Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_1400_480)">
                  <rect x="0.104492" width="14" height="14" fill="#fff"/>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center" style="height: 100%" v-else>
        <v-progress-circular
          class="loading-spinner"
          indeterminate
        ></v-progress-circular>
      </div>
      <div v-if="fileName" class="filename">
        <div class="pop-300 white--text">
          <img class="pb-1" src="@/images/CloudChat.svg" alt=""/>
          <span class="pl-2">
            {{ fileName }}
          </span>
        </div>
        <div class="red--text pop-400 pointer" @click="removeFile()">
          <img src="@/images/RemoveFileChat.svg" alt=""/>
        </div>
      </div>
      <div class="chat-input-grid">
        <div class="form-block w-form">
          <form name="chat-message" @submit.prevent>
            <input
              :class="{ 'message-is-missing': messageMissing }"
              @click="setSeen"
              @keydown.enter="sendMessage"
              class="message-text-2 w-input"
              maxlength="256"
              type="text"
              placeholder="Napíšte odpoveď .."
              v-model="messageValue"
            />
            <input type="submit" id="submit-form" class="d-none"/>
          </form>
        </div>
        <div class="chat-ctas">
          <quick-response-chat-handler/>
          <div class="image-upload-btn">
            <label for="file-upload" style="margin-bottom: 0 !important;">
              <img
                src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f1e_icons8-upload-to-the-cloud.svg"
                class="nav-link-icon small filter-primary"
              >
            </label>
            <input @change="handleFiles" id="file-upload" type="file"/>
          </div>
          <span class="send-button pointer" @click="sendMessage">
            <img class="nav-link-icon small white" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f2c_icons8-sent.svg">
          </span>
        </div>
      </div>
    </div>
    <v-scroll-y-transition>
      <div class="notification-div" style="display: flex" v-if="brokerSaved">
        <div class="success-message">
          <img
            src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/6491435a2f088d3de874c9c7_success.svg"
            loading="lazy"
            alt=""
            class="image-4"
          />
          <div>úspešne ste priradili ku klientovi bankára</div>
        </div>
      </div>
    </v-scroll-y-transition>
    <v-scroll-y-transition>
      <div class="notification-div" style="display: flex" v-if="clientArchived">
        <div class="success-message">
          <img
            src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/6491435a2f088d3de874c9c7_success.svg"
            loading="lazy"
            alt=""
            class="image-4"
          />
          <div>
            {{ archived ? "archivácia" : "obnova" }} klienta prebehla úspešne
          </div>
        </div>
      </div>
    </v-scroll-y-transition>
  </div>
</template>

<script>
import {
  sendMessage,
  getMessages,
  setMessageSeen,
} from "@/services/brokerService";
import {
  getUserProfileByID,
  patchClientProfile,
} from "@/services/brokerService";
import BrokerPickerHandler from "../../shared/PopupHandler/Popups/BrokerPicker/BrokerPickerHandler";
import MortgagePickerHandler from "../../shared/PopupHandler/Popups/MortgagePicker/MortgagePickerHandler";
import UserManagerHandler from "../../shared/PopupHandler/Popups/UserManager/UserManagerHandler";
import imagesConvert from "../../../mixins/imagesConvert";
import QuickResponseChatHandler from "../../shared/PopupHandler/Popups/QuickResponseChat/QuickResponseChatHandler";
import translateFromDBValue from "../../../mixins/translateFromDBValue";
import ClientChatShareHandler from "../../shared/PopupHandler/Popups/ClientChatShare/ClientChatShareHandler.vue";
import router from "@/router";

export default {
  name: "ChatContent",
  props: {
    client: Object,
    quickMessage: [String, null],
  },
  components: {
    ClientChatShareHandler,
    QuickResponseChatHandler,
    UserManagerHandler,
    MortgagePickerHandler,
    BrokerPickerHandler,
  },
  watch: {
    quickMessage (newMessage) {
      if (!newMessage) return;
      this.messageValue = newMessage;
      this.$emit("quickMessageSet");
    },
  },
  mixins: [
    imagesConvert,
    translateFromDBValue,
  ],
  data: () => ({
    clientComp: false,
    detailComp: false,
    mortgageComp: false,
    checkPoints: 0,
    progressBarData: [],
    urlRegex: /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi,
    clientArchived: false,
    archived: null,
    brokerSaved: false,
    showBrokerPicker: false,
    brokerPickerOpened: false,
    showClientDetail: false,
    clientDetailOpened: false,
    clientInfoInitialized: false,
    clientChatInitialized: false,
    messageMissing: false,
    file: "",
    fileName: "",
    messagesIds: [],
    unseenIds: [],
    messages: [],
    messageValue: "",
    clientRefresh: null,
    role: null,
    brokerImage: null,
    financnici: null,
    innerHeightCustom: null,
    chatinterval: null,
  }),
  methods: {
    getClientShareInfo() {
      return {
        label: `${this.client.first_name} ${this.client.last_name}`,
        id: this.client.id,
      }
    },
    checkProgressBar() {
      if (this.client.mortgages.length === 0) return;
      this.checkPoints = 0;
      this.progressBarData = [];
      const mortgage = this.client.mortgages[0];
      if (mortgage.prva_analyza_ciel_dt) {
        const date = new Date(this.client.mortgages[0].prva_analyza_ciel_dt);
        this.checkPoints++;
        this.progressBarData.push({
          label: `účel ${this.getMortgagePurpose().toUpperCase()}`,
          date:`${date.getUTCDate()}.${date.getUTCMonth()}.${date.getUTCFullYear()}`,
        });
      } else {
        this.progressBarData.push({
          label: "účel",
          date: null,
        });
      }
      if (mortgage.prva_analyza_pridany_prijem_dt) {
        const date = new Date(this.client.mortgages[0].prva_analyza_pridany_prijem_dt)
        this.checkPoints++;
        this.progressBarData.push({
          label: `príjem ${this.mapIncomeType(this.client.incomes).toUpperCase()}`,
          date:`${date.getUTCDate()}.${date.getUTCMonth()}.${date.getUTCFullYear()}`,
        });
      } else {
        this.progressBarData.push({
          label: "príjem",
          date: null
        });
      }
      if (mortgage.prva_analyza_vypocitane_dti_dt) {
        const date = new Date(this.client.mortgages[0].prva_analyza_vypocitane_dti_dt);
        this.checkPoints++;
        this.progressBarData.push({
          label: `DTI ${this.calculateDti()}`,
          date:`${date.getUTCDate()}.${date.getUTCMonth()}.${date.getUTCFullYear()}`,
        });
      } else {
        this.progressBarData.push({
          label: "DTI",
          date: null
        });
      }
      if (mortgage.prva_analyza_registracia_dt) {
        const date = new Date(this.client.mortgages[0].prva_analyza_registracia_dt);
        this.checkPoints++;
        this.progressBarData.push({
          label: "registrácia",
          date:`${date.getUTCDate()}.${date.getUTCMonth()}.${date.getUTCFullYear()}`,
        });
      } else {
        this.progressBarData.push({
          label: "registrácia",
          date: null,
        });
      }
    },
    calculateDti() {
      return 158000;
    },
    mapIncomeType(incomes) {
      const incomesStored = incomes.sort((a, b) => new Date(a.created) - new Date(b.created));
      return this.translateIncomeType(incomesStored[0].druh);
    },
    getMortgagePurpose () {
      let maxAmount = Number.MIN_SAFE_INTEGER;
      const mortgage = this.client.mortgages[0];
      let purpose = null;
      const amounts = {
        suma_nadobudnutie: mortgage.suma_nadobudnutie,
        suma_refinancovanie: mortgage.suma_refinancovanie,
        suma_bezucel: mortgage.suma_bezucel,
      };
      for (const [key, amount] of Object.entries(amounts)) {
        if (amount < maxAmount) continue;
        maxAmount = amount;
        purpose = key;
      }
      if (maxAmount === 0) {
        purpose = mortgage.ciel_prva_analyza;
      }
      switch (purpose) {
        case "suma_bezucel":
        case "americka_hypo":
          purpose = "bezucel";
          break;
        case "suma_refinancovanie":
        case "refinancovanie":
        case "konsolidacia":
          purpose = "refinancovanie";
          break;
        case "suma_nadobudnutie":
        case "nadobudnutie":
        case "uverovy_certifikat":
          purpose = "nadobudnutie";
          break;
        default:
          purpose = "nadobudnutie";
          break;
      }
      return purpose;
    },
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
    isUrl (message) {
      const urlifed = message.replace(this.urlRegex, (url) => {
        return '<a href="' + url + '">' + url + "</a>";
      });
      return urlifed === message;
    },
    urlify (message) {
      return message.replace(this.urlRegex, (url) => {
        return (
          '<a class="messageURL" href="' + url + '" target="_blank" style="color: white; word-wrap: break-word">' + url + '</a>'
        )
      });
    },
    getMessageClass (message) {
      if (message.sender === this.client.id) return "message-answer";
      else return "message-question";
    },
    showDetailOfClient () {
      if (!this.clientDetailOpened) {
        this.showBrokerPicker = false;
        this.clientDetailOpened = true;
        setTimeout(() => {
          this.isDetailOpen = true;
          this.showClientDetail = true;
        }, 100);
      }
    },
    pickBroker () {
      this.showClientDetail = false;
      this.brokerPickerOpened = true;
      setTimeout(() => {
        this.isBrokerPickerOpen = true;
        this.showBrokerPicker = true;
      }, 100);
    },
    brokerAssigned () {
      this.hideDetail();
      this.initializeClient();
      this.brokerSaved = true;
      setTimeout(() => {
        this.brokerSaved = false;
      }, 2000);
    },
    hideDetail () {
      if (this.isBrokerPickerOpen || this.isDetailOpen) {
        this.showBrokerPicker = false;
        this.showClientDetail = false;
        this.isBrokerPickerOpen = false;
        this.isDetailOpen = false;
        this.selectedClient = null;
        this.idOfClientToSelectBroker = null;
        this.clientDetailOpened = false;
        this.brokerPickerOpened = false;
      }
    },
    archiveClient () {
      this.hideDetail();
      let client = {
        clientProfileId: this.client.client_profile.id,
        clientProfile: {
          ob_status: null,
        },
      };
      switch (this.client.client_profile.ob_status) {
        case "archivovany":
          client.clientProfile.ob_status = "novy";
          break;
        case "novy":
        case "rozpracovaný":
          client.clientProfile.ob_status = "archivovany";
          break;
      }
      patchClientProfile(client.clientProfile, client.clientProfileId).then(
        () => {
          this.archived =
            this.client.client_profile.ob_status !== "archivovany";
          this.initializeClient();
          this.clientArchived = true;
          setTimeout(() => {
            this.clientArchived = false;
          }, 2000);
        }
      );
    },
    async getBrokerImage () {
      const financnikId = this.client.financnik;
      if (financnikId) {
        const userProfile = await getUserProfileByID(financnikId)
        if (userProfile.profile_image) {
          this.brokerImage = userProfile.profile_image;
        } else {
          this.brokerImage = require("@/images/avatar-1-icon.png");
        }
      } else {
        this.brokerImage = require("@/images/avatar-1-icon.png");
      }
    },
    getStatus () {
      switch (this.client.client_profile.ob_status) {
        case "novy":
          return "temporaryClients";
        case "rozpracovaný":
          return "registeredClients";
        case "archivovany":
          return "archivedClients";
      }
    },
    handleFiles () {
      const file = document.getElementById("file-upload").files[0];
      this.fileName = file.name;
      this.getBase64(file);
    },
    removeFile () {
      this.file = "";
      this.fileName = "";
    },
    getBase64 (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.file = reader.result;
      };
    },
    setSeen () {
      if (this.unseenIds.length > 0) {
        this.unseenIds.forEach((id) => {
          setMessageSeen(id);
        });
        this.unseenIds = [];
      }
    },
    async setMessages () {
      let messagesArray = [];
      let messagesIds = [];
      await getMessages(this.client.id).then((messages) => {
        messages.results.forEach((message) => {
          messagesIds.push(message.id);
          messagesArray.push(message);
          if (message.seen_at === null && message.sender === this.client.id) {
            this.unseenIds.push(message.id);
          }
        });
        this.clientChatInitialized = true;
      });
      this.messagesIds = messagesIds;
      this.messages = messagesArray;
    },
    //opravane na zaklade chat gpt 21_2_2025 pre prilis vela dopytov
    async appendNewMessages() {
      if (this.unseenIds.length > 0) {
        const messages = await getMessages(this.client.id);
        messages.results.forEach(message => {
          if (!this.messagesIds.includes(message.id)) {
            this.messages.unshift(message);
            this.messagesIds.unshift(message.id);
            this.unseenIds.push(message.id);
          }
        });
      }
    },
    //opravane na zaklade chat gpt 21_2_2025 pre prilis vela dopytov
    async initializeChat(retries = 1) {
      if (this.client.id) {
        await this.setMessages();
        this.setSeen();
        this.chatinterval = setInterval(this.appendNewMessages, 10000);
      } else if (retries < 5) {
        setTimeout(() => this.initializeChat(retries + 1), retries * 200);
      }
    },
    setFinancnik () {
      this.role = this.$store.getters["userState/getRole"];
      if (this.role === "alex_admin") return;
      if (this.clientInfoInitialized && !this.client.financnik) {
        patchClientProfile(
          {financnik: this.$store.getters["userState/getId"]},
          this.client.client_profile.id
        );
      }
    },
    sendFile () {
      if (this.fileName !== "" && this.file !== "") {
        this.messageValue = "súbor";
        this.fileName = this.fileName.replace(/\s/g, "");
        sendMessage(
          this.client.id,
          this.messageValue,
          this.file,
          this.fileName
        ).then((res) => {
          this.messages.unshift(res);
          this.messagesIds.unshift(res.id);
        });
        this.messageMissing = false;
        this.messageValue = "";
        this.file = "";
        this.fileName = "";
      } else {
        this.messageMissing = true;
      }
    },
    sendTextMessage () {
      if (this.messageValue !== "") {
        if (this.fileName !== "" && this.file !== "") {
          sendMessage(
            this.client.id,
            this.messageValue,
            this.file,
            this.fileName
          ).then((res) => {
            this.messages.unshift(res);
            this.messagesIds.unshift(res.id);
          });
        } else {
          sendMessage(this.client.id, this.messageValue, null, null).then(
            (res) => {
              this.messages.unshift(res);
              this.messagesIds.unshift(res.id);
            }
          );
        }
        this.messageValue = "";
        this.file = "";
        this.fileName = "";
        this.messageMissing = false;
      } else {
        this.sendFile();
      }
    },
    sendMessage () {
      this.setFinancnik();
      this.sendTextMessage();
    },
  },
  //pridane na zaklade chat gpt 21_2_2025 pre prilis vela dopytov
  async waitForLoading() {
      let loading = this.$store.getters["appState/getLoadingMainData"];
      if (!loading) {
        this.initializeChat();
      } else {
        setTimeout(this.waitForLoading, 200);
      }
  },
  mounted () {
    this.checkProgressBar();
    let loading = this.$store.getters["appState/getLoadingMainData"];
    const int = setInterval(() => {
      loading = this.$store.getters["appState/getLoadingMainData"];
      if (!loading) {
        clearInterval(int);
      }
    }, 50);
    const int1 = setInterval(() => {
      if (!loading) {
        this.initializeChat();
        clearInterval(int1);
      }
    }, 50);
  },
  beforeDestroy () {
    clearInterval(this.chatinterval);
  },
};
</script>

<style scoped lang="scss">

.popup .popup-form {
  width: 85% !important;
}

</style>
