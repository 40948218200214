<template>
  <div class="popup-form">
    <div
      class="content-form-popup"
    >
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg" alt=""/>
        </div>
        <div class="_900 big">
          Úprava bonity
        </div>
        <button form="kids-form" class="w-button saveformBtn">Uložiť</button>
      </div>

      <div class="w-form">
          <div class="header-block inline">
            <div>
              <div class="bold noselect">Žiadateľ</div>
              <div class="text-cta justify-content-start" @click="editBonitaOf(applicant, 'Žiadateľ')">EDIT</div>
            </div>
            <img
              src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f37_avatar%20cropped.png"
              loading="lazy"
              alt=""
              class="image-10"
            />
          </div>
          <div class="header-block inline" v-if="coApplicant ? coApplicant : null">
            <div>
              <div class="bold noselect">Spolužiadateľ</div>
              <div class="text-cta justify-content-start" @click="editBonitaOf(coApplicant, 'Spolužiadateľ')">EDIT</div>
            </div>
            <img
              src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f37_avatar%20cropped.png"
              loading="lazy"
              alt=""
              class="image-10"
            />
          </div>
          <div class="sub-popup-wrapper" v-else>
            <client-add-handler :rules="['single']" :client-info="{type: 'Spolužiadateľ'}"/>
          </div>
          <div
            class="splitter"
            v-if="!(form.kids_shared || form.kids_applicant || form.kids_coapplicant)"
          >
          </div>
          <div
            class="splitter"
            v-else
          >
            Počítame s celkovým počtom detí {{ (form.kids_applicant + form.kids_coapplicant - form.kids_shared) }}
          </div>
          <form id="kids-form" @submit.prevent="saveKidsForm">
            <div class="input-group">
              <label for="deti-pocet-z" class="field-label"
              >Počet detí žiadateľa</label
              ><input
              @click="applicantKidsChanged = true"
              v-model.number="form.kids_applicant"
              type="number"
              class="text-input w-input required"
              maxlength="256"
              name="deti-pocet-z"
              id="deti-pocet-z"
              required
            />
            </div>
            <div v-if="coApplicant">
              <div class="input-group">
                <label for="deti-pocet-s" class="field-label"
                >Počet detí spolužiadateľa</label
                ><input
                @click="coApplicantKidsChanged = true"
                v-model.number="form.kids_coapplicant"
                type="number"
                class="text-input w-input required"
                maxlength="256"
                name="deti-pocet-s"
                id="deti-pocet-s"
                required
              />
              </div>
              <div class="input-group">
                <label for="deti-pocet-t" class="field-label"
                >Z toho počet spoločných detí</label
                ><input
                @click="mortgageKidsChanged = true"
                v-model.number="form.kids_shared"
                type="number"
                min="0"
                :max="maxShared"
                class="text-input w-input required"
                maxlength="256"
                name="deti-pocet-t"
                id="deti-pocet-t"
                required
              />
              </div>
            </div>
          </form>
          <div class="section-title">
            <div class="light">
                Zadajte všetky deti do veku 21 rokov, vrátane, do tohto veku platí vyživovacia povinnosť.
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClientAddHandler from "../ClientAdd/ClientAddHandler";
import { mapGetters } from "vuex";

export default {
  name: "ClientBonitaPicker",
  components: {ClientAddHandler},
  computed: {
    ...mapGetters({
      applicantGetter: "mortgageDetailState/getApplicant",
      coApplicantGetter: "mortgageDetailState/getCoApplicant",
      mortgageGetter: "mortgageDetailState/getMortgage",
    }),
  },
  watch: {
    applicantGetter (newApplicant) {
      this.applicant = newApplicant;
    },
    coApplicantGetter (newCoApplicant) {
      this.coApplicant = newCoApplicant;
    },
    mortgageGetter (newMortgage) {
      this.mortgage = newMortgage;
      this.form.kids_shared = newMortgage.pocet_spolocnych_nezaopatrenych_deti;
    },
    "form.kids_applicant" (kids) {
      if (kids < this.form.kids_coapplicant) {
        this.maxShared = kids;
      } else {
        this.maxShared = this.form.kids_coapplicant;
      }
    },
    "form.kids_coapplicant" (kids) {
      if (kids < this.form.kids_applicant) {
        this.maxShared = kids;
      } else {
        this.maxShared = this.form.kids_applicant;
      }
    },
    "form.kids_shared" (kids) {
      if (kids === "") return;
      if (kids !== 0 && this.form.kids_applicant === 0) {
        this.applicantKidsChanged = true;
        this.form.kids_applicant = kids;
      }
      if (kids !== 0 && this.form.kids_coapplicant === 0) {
        this.coApplicantKidsChanged = true;
        this.form.kids_coapplicant = kids;
      }
    },
  },
  created () {
    this.applicant = this.$store.getters["mortgageDetailState/getApplicant"];
    this.coApplicant = this.$store.getters["mortgageDetailState/getCoApplicant"];
    this.mortgage = this.$store.getters["mortgageDetailState/getMortgage"];
    this.form.kids_applicant = this.applicant.client_profile.pocet_nezaopatrenych_deti;
    this.form.kids_shared = this.mortgage.pocet_spolocnych_nezaopatrenych_deti;
    if (!this.coApplicant) return;
    this.form.kids_coapplicant = this.coApplicant.client_profile.pocet_nezaopatrenych_deti;
  },
  methods: {
    async saveKidsForm () {
      if (this.applicantKidsChanged) {
        const bonitaObjectApplicant = {
          id: this.applicant.client_profile.id,
          object: {
            pocet_nezaopatrenych_deti: this.form.kids_applicant,
          },
          clientLabel: "Žiadateľ",
          clientRole: "applicant",
        };
        this.$store.dispatch("mortgageDetailState/patchClientProfileObject", bonitaObjectApplicant);
      }
      setTimeout(() => {
        if (this.coApplicant && this.coApplicantKidsChanged) {
          const bonitaObjectCoApplicant = {
            id: this.coApplicant.client_profile.id,
            object: {
              pocet_nezaopatrenych_deti: this.form.kids_coapplicant,
            },
            clientLabel: "Spolužiadateľ",
            clientRole: "coApplicant",
          };
          this.$store.dispatch("mortgageDetailState/patchClientProfileObject", bonitaObjectCoApplicant);
        }
      }, 1);
      if (this.mortgageKidsChanged) {
        const mortgage = {
          id: this.mortgage.id,
          object: {
            pocet_spolocnych_nezaopatrenych_deti: this.form.kids_shared,
          }
        };
        this.$store.dispatch("mortgageDetailState/patchMortgage", mortgage);
      }
      this.closePopup()
    },
    editBonitaOf (client, type) {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: {client, type},
        component: "ClientBonitaPopUp",
        rules: ["single"],
      });
    },
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
  },
  data: () => ({
    mortgageKidsChanged: false,
    coApplicantKidsChanged: false,
    applicantKidsChanged: false,
    initialized: false,
    applicant: null,
    coApplicant: null,
    mortgage: null,
    maxShared: 0,
    form: {
      kids_applicant: null,
      kids_coapplicant: null,
      kids_shared: null,
    }
  }),
};
</script>

<style lang="scss" scoped>
.popup {
  width: 34%;
  position: absolute !important;
  top: 0;
  overflow-y: auto;
  transition-duration: 0.5s;
}
</style>
